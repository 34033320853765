// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faPhone as fasFaPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";

import { faFacebookF as farFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter as farFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faInstagram as farFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedinIn as farFaLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faYoutube as farFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add solid icons
 */
library.add(fasFaPhone);

/**
 * Add brands icons
 */
library.add(farFaFacebookF, farFaTwitter, farFaInstagram, farFaLinkedinIn, farFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
